import { Card, Container, Button, Row, Col, Carousel } from "react-bootstrap";
// import { useState , useEffect } from 'react'

import { Link } from "react-router-dom";

import stonesImg from "../../images/custom/stones-pile.jpg";
import psihoterapeut_img from "../../images/Poze_My_clinic//PsihoterapieStefania.jpg";
import stonesSmallImg from "../../images/custom/stones-pile-small.jpg";
import miculDoctor1_img from "../../images/Poze_My_clinic/MiculDoctor1.png";
import psihoterapieSalaAsteptare_img from "../../images/Poze_My_clinic/SalaAsteptarePsihoterapie.jpg";
import experientaCarte_img from "../../images/Poze_My_clinic/bookWithPagesTurning.jpg";
import miculDoctor3_img from "../../images/Poze_My_clinic/MiculDoctor3.png";
import medicinaDeFamilie_img from "../../images/home-images/medicina_de_familie.jpg";
import medicinaMunciiCabinet_img from "../../images/Poze_My_clinic/cabinetMedicinaMuncii.jpg";
import familia_img from "../../images/home-images/familia.jpg";
import medicina_muncii_img from "../../images/madicina-muncii/1_oferta_noastra.jpg";
import psihologie_img from "../../images/home-images/psihologie_brain.jpg";

const Home = () => {
  // calculates how many years the company is on the market
  const yearsInTheMarket = () => {
    return new Date().getFullYear() - 2008;
  };

  return (
    <>
      <Carousel>
        <Carousel.Item style={{ maxHeight: "600px" }}>
          <Row style={{ backgroundColor: "#8FC1E3" }}>
            <Col style={{ backgroundColor: "#8FC1E3", color: "#fff" }}>
              <img
                className="d-block w-100 carusel-image-contain"
                alt="imagine cabinet"
                src={miculDoctor3_img}
              />
            </Col>
            <Col style={{ backgroundColor: "#8FC1E3", color: "#fff" }}>
              <img
                className="d-block w-100 carusel-image-contain"
                alt="imagine cabinet"
                src={miculDoctor1_img}
              />
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item style={{ maxHeight: "600px" }}>
          <Row style={{ backgroundColor: "#8FC1E3" }}>
            <Col style={{ backgroundColor: "#8FC1E3", color: "#fff" }}>
              <h3
                style={{ color: "#fff", marginTop: "3rem" }}
                className="text-center"
              >
                Experienta
              </h3>
              <p style={{ marginTop: "2rem", color: "#fff" }} className="ml-2">
                De {yearsInTheMarket()} ani va oferim asistență medicală de cea
                mai bună calitate.
              </p>
              <p className="ml-2">
                Ne bazăm pe stabilira unei relații strânse între medic și
                pacienți, acesta devenind managerul stării de sănătate atât
                acasă cât și la locul de muncă.
              </p>
            </Col>
            <Col>
              <img
                className="d-block w-100 carusel-image-cover"
                src={experientaCarte_img}
                alt="First slide"
              />
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item style={{ maxHeight: "600px" }}>
          <Row style={{ backgroundColor: "#8FC1E3" }}>
            <Col style={{ backgroundColor: "#8FC1E3", color: "#fff" }}>
              <h3
                style={{ color: "#fff", marginTop: "3rem" }}
                className="text-center"
              >
                Psihoterapie
              </h3>

              <p className="ml-2">
                Psihoterapia este tipul de activitate psihologică profesională
                centrată pe relația de ajutor în situațiile de criză personală
                sau colectivă, de impas existențial iminent sau trenant, de
                dificultate în adaptare, relaționare și integrare socială.
              </p>
            </Col>
            <Col>
              <img
                className="d-block w-100 carusel-image-cover"
                src={psihoterapeut_img}
                alt="First slide"
              />
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item style={{ maxHeight: "600px" }}>
          <Row style={{ backgroundColor: "#8FC1E3" }}>
            <Col style={{ backgroundColor: "#8FC1E3", color: "#fff" }}>
              <h3
                style={{ color: "#fff", marginTop: "3rem" }}
                className="text-center"
              >
                Medicina de familie
              </h3>
              <p style={{ marginTop: "2rem" }} className="ml-2">
                My Clinic îşi propune să vă ofere asistenţă medicală de cea mai
                bună calitate, dar în primul rând să vă menţinem sănătoşi. Poti
                beneficia de reduceri la serviciile oferite inscriindu-te la
                unul din medicii nostrii de familie.
              </p>
            </Col>
            <Col>
              <img
                className="d-block w-100 carusel-image-cover"
                src={medicinaDeFamilie_img}
                alt="Second slide"
              />
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item style={{ maxHeight: "600px" }}>
          <Row style={{ backgroundColor: "#8FC1E3" }}>
            <Col style={{ backgroundColor: "#8FC1E3", color: "#fff" }}>
              <h3
                style={{ color: "#fff", marginTop: "3rem" }}
                className="text-center"
              >
                Medicina muncii
              </h3>
              <p style={{ marginTop: "2rem" }} className="ml-2">
                În cadrul controlului de medicina muncii se realizează un
                complex de examinări medicale clinice, biologice şi paraclinice
                conform normelor stipulate prin lege pentru fiecare tip de
                profesie.
              </p>
            </Col>
            <Col>
              <img
                className="d-block w-100 carusel-image-cover"
                src={medicinaMunciiCabinet_img}
                alt="Third slide"
              />
            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>

      <Container id="home-container">
        <Row className="d-flex justify-content-around gap-3">
          <Col
            xs={12}
            lg={3}
            className="d-flex justify-content-around"
            style={{ marginBottom: "1rem" }}
          >
            <Card
              style={{ width: "18rem", background: "transparent" }}
              className="mb-4 h-100 box-shadow-left"
            >
              <Card.Img
                variant="top"
                src={medicina_muncii_img}
                className="card-image"
              />
              <Card.Body className="d-flex flex-column">
                <Card.Title className="text-center">Medicina muncii</Card.Title>
                <Card.Text className="card-small">
                  În cadrul controlului de medicina muncii se realizează un
                  complex de examinări medicale clinice, biologice şi
                  paraclinice conform normelor stipulate prin lege pentru
                  fiecare tip de profesie.
                </Card.Text>
                <Button
                  variant="info"
                  className="mt-auto"
                  as={Link}
                  to="/medicina-muncii/oferta"
                >
                  Citește mai mult
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col
            xs={12}
            lg={3}
            className="d-flex justify-content-around"
            style={{ marginBottom: "1rem" }}
          >
            <Card
              style={{ width: "18rem", background: "transparent" }}
              className="mb-4 h-100 box-shadow-left"
            >
              <Card.Img
                variant="top"
                src={psihologie_img}
                className="card-image"
              />
              <Card.Body className="d-flex flex-column ">
                <Card.Title className="text-center ">
                  Psihologie - Psihoterapie
                </Card.Title>
                <Card.Text className="card-small">
                  Psihoterapia este tipul de activitate psihologică profesională
                  centrată pe relația de ajutor în situațiile de criză personală
                  sau colectivă, de impas existențial iminent sau trenant, de
                  dificultate în adaptare, relaționare și integrare socială.
                </Card.Text>
                <Button
                  variant="info"
                  className="mt-auto"
                  as={Link}
                  to="/psihologie/psihoterapie"
                >
                  Citește mai mult
                </Button>
              </Card.Body>
            </Card>
          </Col>

          <Col
            xs={12}
            lg={3}
            className="d-flex justify-content-around"
            style={{ marginBottom: "1rem" }}
          >
            <Card
              style={{ width: "18rem", background: "transparent" }}
              className="mb-4  h-100 box-shadow-left"
            >
              <Card.Img
                variant="top"
                src={familia_img}
                className="card-image"
              />
              <Card.Body className="d-flex flex-column">
                <Card.Title className="text-center ">
                  Medicina de familie
                </Card.Title>
                <Card.Text className="card-small">
                  My Clinic îşi propune să vă ofere asistenţă medicală de cea
                  mai bună calitate, dar în primul rând să vă menţinem sănătoşi.
                </Card.Text>
                <Button
                  variant="info"
                  className="mt-auto"
                  as={Link}
                  to="/medicina-de-famillie/deCeSaViiLaNoi"
                >
                  Citește mai mult
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
