import React from 'react'
import { Container , Card , Image } from 'react-bootstrap'
import documenteImg from '../../../images/medicina-de-familie/4_documente_necesare.gif'
import documente2Img from '../../../images/medicina-de-familie/4_documente_necesare2.jpg'

const DocumenteNecesareAsiguratilor = () => {
  return (
    <Container>
        <Card className='box-shadow-left m-3 p-3'>
            <h1 className='mb-3 p-3'>Documente necesare asiguraților</h1>
            <div>
                <Image src={documenteImg} className='image-container-right'/>
                <p>
                    În dată de 19 septembrie a intrat în vigoare Ordinul 581/2014 al 
                    Casei Naționale de Asigurări de Sănătate prin care se aprobă 
                    normele metodologice pentru stabilirea documentelor justificative 
                    privind dobândirea calității de asigurat.  Totodată, începând cu 
                    dată intrării în vigoare a acestui ordin, Ordinul președintelui 
                    Casei Naționale de Asigurări de Sănătate nr. 617/2007 pentru 
                    aprobarea Normelor metodologice privind stabilirea documentelor 
                    justificative pentru dobândirea calității de asigurat, respectiv 
                    asigurat fără plata contribuției, precum și pentru aplicarea 
                    măsurilor de executare silită pentru încasarea sumelor datorate la 
                    Fondul național unic de asigurări sociale de sănătate, publicat în 
                    Monitorul Oficial al României, Partea I, nr. 649 din 24 septembrie 
                    2007, cu modificările și completările ulterioare, se abrogă.

O           rdinul 581/2014 al CNAS a fost publicat în Monitorul Oficial nr. 685/2014.
                </p>
                <h5>Norme metodologice pentru stabilirea documentelor justificative privind dobandirea calitatii de asigurat</h5>
                <h5>CAPITOLUL I - Dispoziții generale</h5>
                <h5>Art. 1</h5>
                  <ol>
                    <li>
                      Fondul național unic de asigurări sociale de sănătate, denumit în continuare fondul, 
                      se constituie din contribuții ale persoanelor fizice și juridice, subvenții de la bugetul 
                      de stat, precum și din donații, sponsorizări, dobânzi, venituri obținute din exploatarea 
                      patrimoniului Casei Naționale de Asigurări de Sănătate, denumită în continuare CNAS, și al 
                      caselor de asigurări de sănătate județene, respectiv a municipiului București, precum și al 
                      Casei Asigurărilor de Sănătate a Apărării, Ordinii Publice, Siguranței Naționale și 
                      Autorității Judecătorești, denumite în continuare case de asigurări, precum și din alte 
                      venituri, 
                      inclusiv sume din veniturile proprii ale Ministerului Sănătății, în condițiile legii.
                    </li>
                    <li>
                      Colectarea contribuțiilor persoanelor juridice sau fizice care au calitatea de angajator, 
                      precum și a contribuțiilor datorate de persoanele fizice obligate să se asigure în 
                      condițiile legii se face de către Ministerul Finanțelor Publice, prin 
                      Agenția Națională de Administrare Fiscală, denumită în continuare ANAF, și 
                      organele fiscale subordonate acesteia, în contul unic deschis pe seama CNAS, 
                      cu respectarea dispozițiilor Ordonanței Guvernului nr. 92/2003 privind Codul 
                      de procedura fiscală, republicată, cu modificările și completările ulterioare, 
                      denumit în continuare Codul de procedura fiscală, în condițiile legii.
                    </li>
                    <li>
                      Competență de administrare a contribuțiilor sociale obligatorii datorate de persoanele 
                      fizice prevăzute la cap. ÎI și III din titlul IX2 al Legii nr. 571/2003 privind Codul 
                      fiscal, cu modificările și completările ulterioare, denumită în continuare Codul fiscal, 
                      revine ANAF, potrivit Codului fiscal. Prin administrarea contribuțiilor se înțelege 
                      ansamblul activităților desfășurate de organele fiscale în legătură cu: înregistrarea 
                      fiscală, declararea, stabilirea, verificarea și colectarea contribuțiilor, precum și 
                      soluționarea contestațiilor împotriva actelor administrative fiscale, în condițiile legii.</li>
                    <li>
                      Documentele prin care se atestă calitatea de asigurat în sistemul de asigurări 
                      sociale de sănătate din România sunt, după caz, adeverință de asigurat eliberată 
                      prin grijă casei de asigurări în evidență căreia este înscris asiguratul sau documentul 
                      rezultat prin accesarea de către furnizorii aflați în relații contractuale cu casele de 
                      asigurări a instrumentului electronic pus la dispoziție de CNAS, 
                      constituit în baza Registrului unic de evidență al asiguraților.
                    </li>
                    <li>
                      Dovadă calității de asigurat se face prin documentele prevăzute la alin. (4), 
                      reprezentând o măsură 
                      tranzitorie până la înlocuirea cu cardul național de asigurări sociale de sănătate.
                    </li>
                    <li>
                      Datele necesare pentru stabilirea calității de asigurat vor fi transmise în mod 
                      gratuit caselor de asigurări de către autoritățile, instituțiile publice și alte 
                      instituții, pe baza de protocol, cu respectarea Legii nr. 677/2001 pentru protecția 
                      persoanelor cu privire la prelucrarea datelor cu caracter personal și liberă circulație
                       a acestor date, cu modificările și completările ulterioare, și a Legii nr. 506/2004 
                       privind prelucrarea datelor cu caracter personal și protecția vieții 
                      private în sectorul comunicațiilor electronice, cu modificările și completările ulterioare.
                    </li>
                  </ol>
                <h5>Art. 2</h5>
                <p>
                  În cadrul sistemului de asigurări sociale de sănătate din România, potrivit 
                  Legii nr. 95/2006 privind reforma în domeniul sănătății, cu modificările și 
                  completările ulterioare, Codului fiscal și prezențelor norme metodologice, sunt asigurați:
                </p>
                <ol>
                  <li>
                     toți cetățenii români cu domiciliul în țară și 
                     care fac dovadă plății contribuției la fond, în condițiile legii;
                  </li>
                  <li>
                     cetățenii străini și apatrizii care au solicitat și au obținut prelungirea 
                     dreptului de ședere temporară ori au 
                     domiciliul în România și care fac dovadă plății contribuției la fond, în condițiile legii;
                  </li>
                  <li>
                    cetățenii statelor membre ale Uniunii Europene, Spațiului Economic European și 
                    Confederației Elvețiene care nu dețîn o asigurare încheiată pe teritoriul altui 
                    stat membru care produce efecte pe teritoriul României, care au solicitat și 
                    obținut dreptul de rezidență în România, pentru o perioada 
                    de peste 3 luni, și care fac dovadă plății contribuției la fond, în condițiile legii;
                  </li>
                  <li>
                    persoanele din statele membre ale Uniunii Europene, Spațiului Economic European și 
                    Confederației Elvețiene care îndeplinesc condițiile de lucrător frontalier, și anume 
                    desfășoară o activitate salariată sau independența în România și care rezidă în alt 
                    stat membru în care se întorc de regulă zilnic sau cel puțîn o 
                    dată pe săptămâna și care fac dovadă plății contribuției la fond, în condițiile legii;
                  </li>
                  <li>
                    pensionarii din sistemul public de pensii care nu mai au domiciliul în România și 
                    care își stabilesc reședința pe teritoriul unui stat membru al Uniunii Europene, 
                    al unui stat aparțînând Spațiului Economic European sau al Confederației Elvețiene, 
                    respectiv domiciliul pe teritoriul unui stat cu care România aplică un acord 
                    bilateral de securitate socială cu prevederi pentru asigurarea de 
                    boală-maternitate și fac dovadă plății contribuției la fond, în condițiile legii.
                  </li>
                </ol>
                <p>
                  Calitatea de asigurat si drepturile de asigurare inceteaza:
                </p>
                <ol>
                  <li>
                     pentru persoanele prevăzute la alin. (1) lit. a), odată cu pierderea dreptului de 
                     domiciliu în România, precum și în condițiile 
                     art. 258 alin. (2) din Legea nr. 95/2006, cu modificările și completările ulterioare;
                  </li>
                  <li>
                    pentru persoanele prevăzute la alin. (1) lit. b), odată cu pierderea dreptului de ședere 
                    în România, precum și în condițiile 
                    art. 258 alin. (2) din Legea nr. 95/2006, cu modificările și completările ulterioare;
                  </li>
                  <li>
                    pentru persoanele prevăzute la alin. (1) lit. c), odată cu pierderea dreptului de 
                    rezidență în România, pentru o perioada de peste 3 luni, precum și în condițiile 
                    art. 258 alin. (2) din Legea nr. 95/2006, cu modificările și completările ulterioare;
                  </li>
                  <li>
                    pentru persoanele prevăzute la alin. (1) lit. d), odată cu pierderea calității de 
                    lucrător frontalier, precum și în condițiile 
                    art. 258 alin. (2) din Legea nr. 95/2006, cu modificările și completările ulterioare;
                  </li>
                  <li>
                    pentru persoanele prevăzute la alin. (1) lit. e), de la dată la care pensionarilor 
                    sistemului public de pensii din România nu li se mai reține contribuția la fond, 
                    calculată asupra veniturilor din pensia cuvenită în acest sistem.
                  </li>
                </ol>
                <p>
                  Drepturile de asigurare încetează după 3 luni de la ultima plata a contribuției la fond, în condițiile legii și ale prezențelor norme metodologice.
                </p>
                <p>
                  Asigurații au dreptul la pachetul de baza de la dată începerii plății contribuției la fond, 
                  urmând că sumele restante să fie recuperate de ANAF, 
                  în condițiile legii, inclusiv obligații fiscale accesorii datorate pentru creanțele fiscale.
                </p>
                <p>
                  Persoanele asigurate din statele cu care România a încheiat documente internaționale cu 
                  prevederi în domeniul sănătății beneficiază de servicii medicale și alte prestații acordate 
                  pe teritoriul României, în condițiile prevăzute de respectivele documente internaționale.
                </p>
                <h5>CAPITOLUL II - Contribuția de asigurări sociale de sănătate</h5>
                <h5>Art. 3</h5>
                <Image src={documente2Img} className='image-container-right'/>
                <p>Angajatorii și entitățile asimilate angajatorului au obligația să calculeze, 
                  să rețînă și să vireze 
                  contribuția de asigurări sociale de sănătate la termenele și în condițiile Codului fiscal.
                </p>
                <h5>Art. 4</h5>
                <p>
                  Contribuția persoanelor asigurate se stabilește sub formă unei cote prevăzute de lege, 
                  aplicată asupra veniturilor prevăzute în Codul fiscal, la termenele și în condițiile 
                  Codului fiscal, cu excepția persoanelor prevăzute 
                  la art. 213 alin. (1) din Legea nr. 95/2006, cu modificările și completările ulterioare.
                </p>
                <h5>Art. 5</h5>
                <ol>
                  <li>
                  Persoanele asigurate în baza art. 213 alin. (1) lit. b), respectiv soțul, soția și 
                  părinții fără venituri proprii, aflate în întreținerea unei persoane asigurate cu sau 
                  fără plata contribuției, denumite în continuare coasigurati, își păstrează calitatea de 
                  asigurat și drepturile care decurg din această 
                  numai în perioada în care persoană în a cărei întreținere se află are calitatea de asigurat.
                  </li>
                  <li>
                    Coasiguratii nu pot avea la rândul lor coasigurati în baza 
                    art. 213 alin. (1) lit. b) din Legea nr. 95/2006, cu modificările și 
                    completările ulterioare.
                  </li>
                </ol>
                <h5>Art. 6</h5>
                <ol>
                  <li>
                    Persoanele care nu realizează venituri și nu se încadrează în categoriile de persoane 
                    care beneficiază de asigurarea de sănătate fără plata contribuției, pentru a dobândi 
                    calitatea de asigurat, 
                    au obligația plății contribuției individuale lunare de asigurări sociale de sănătate.
                  </li>
                  <li>
                     Persoanele care au obligația să se asigure, care nu pot dovedi plata contribuției și 
                     care nu se încadrează în categoriile de persoane care beneficiază de asigurarea de 
                     sănătate fără plata contribuției, plătesc contribuția lunară de asigurări sociale de 
                     sănătate calculată prin aplicarea cotei 
                     prevăzute în Codul fiscal la salariul de baza minim brut pe țară în vigoare la dată plății.
                  </li>
                  <li>
                    Persoanele prevăzute la alin. (2) sunt obligate pentru a obține calitatea de 
                    asigurat să achite contribuția legală pe ultimele 6 luni și a lunii curente, 
                    calculată la salariul minim brut pe țară în vigoare la dată plății, calculându-se 
                    majorări de întârziere dacă nu au realizat venituri impozabile pe perioada termenelor 
                    de prescripție privind obligațiile fiscale. În situațiile în care persoanele prevăzute 
                    la alin. (2) au realizat venituri impozabile pe perioada termenelor de prescripție 
                    recuperarea sumelor restante cu titlul de contribuție, precum și accesoriile 
                    fiscale se realizează de ANAF, prin organele fiscale teritoriale, în condițiile legii.
                  </li>
                  <li>
                    Plata contribuției de asigurări sociale de sănătate pentru persoanele prevăzute la 
                    alin. (1) se efectuează lunar, la termenele și în condițiile Codului fiscal.
                  </li>
                </ol>
                <h5>Art. 7</h5>
                <p>Cetățenii străini care au obținut permis de ședere în România au obligația plății 
                  contribuției asupra veniturilor impozabile realizate pe teritoriul României, în condițiile 
                  legii, 
                  cu respectarea termenului de prescripție a contribuției și calculându-se accesorii fiscale.
                </p>
                <h5>Art. 8</h5>
                <ol>
                  <li>
                    Străinii beneficiari ai unei forme de protecție potrivit Legii nr. 122/2006 privind azilul în România, 
                    cu modificările și completările ulterioare, sunt obligați, pentru a obține calitatea de asigurat, 
                    să plătească contribuția 
                    de asigurări sociale de sănătate începând cu dată obținerii formei de protecție, în condițiile legii.
                  </li>
                  <li>
                    În situația în care nu realizează venituri impozabile, persoanele prevăzute la alin. (1) plătesc 
                    contribuția lunară calculată la un salariu minim brut pe țară în vigoare la dată plății, 
                    în condițiile art. 6.
                  </li>
                </ol>
                <h5>Art. 9</h5>
                <ol>
                  <li>
                    Pentru lucrătorii migranți care își păstrează domiciliul sau reședința în România, contribuția 
                    lunară la fond se calculează 
                    la veniturile obținute din contractele încheiate cu un angajator străîn și care se impozitează în România.
                  </li>
                  <li>
                    Prevederile alin. (1) nu se aplică în cazul lucrătorilor migranți care își desfășoară activitatea în 
                    statele membre ale Uniunii Europene sau în statele cu care România are 
                    încheiate acorduri de securitate socială și care sunt asigurați în respectivele sisteme de securitate socială.
                  </li>
                </ol>
                <h5>CAPITOLUL III - Documente justificative privind dobândirea calității de asigurat</h5>
                <h5>Art. 10</h5>
                <p>
                  Până la implementarea cardului național de asigurări sociale de sănătate documentele 
                  justificative privind dobândirea calități de asigurat sunt prevăzute la art. 1 alin. (4).
                </p>
                <h5>Art. 11</h5>
                <ol>
                  <li>
                    Modalitatea electronică de verificare a calității de asigurat se face prin introducerea de către 
                    furnizorii de servicii medicale, medicamente, îngrijiri la domiciliu și dispozitive medicale a codului
                     numeric personal al persoanei care necesită servicii medicale, medicamente și dispozitive medicale în 
                     câmpul „CNP“ 
                    din aplicația instalată pe site-ul CNAS la adresa <a href="https://cnas.ro/verificare-asigurati"></a>
                  </li>
                  <li>
                    În cazul în care persoană care necesită servicii medicale, medicamente, îngrijiri la domiciliu și 
                    dispozitive medicale, cu excepția celor din pachetul minim de servicii medicale, că urmare a interogării 
                    aplicației menționate la alin. (1), apare neasigurată, această va face dovadă calității de asigurat cu 
                    adeverință de asigurat. Pentru obținerea adeverinței de asigurat persoană se adresează casei de asigurări 
                    de sănătate 
                    la care această este luată în evidență, prezentând documentele necesare dovedirii calității de asigurat.
                  </li>
                </ol>
                <h5>Art. 12</h5>
                <ol>
                  <li>
                    Dovadă calității de asigurat se realizează prin modalitatea electronică de verificare a 
                    calității de asigurat în sistemul de asigurări sociale de sănătate, iar în situația în care, 
                    în urmă interogării, persoană nu apare în aplicația menționată la art. 11 alin. (1), se realizează 
                    printr-un document justificativ – adeverință, potrivit modelului prevăzut în anexă nr. 1 care 
                    face parte integrantă din prezențele norme metodologice, eliberată de casă de asigurări de 
                    sănătate în a cărei 
                    evidență se află persoană, potrivit fiecărei categorii de persoane, pe baza următoarelor documente:
                  </li>
                    <ol type='a'>
                      <li>
                        pentru copiii în vârstă de până la 18 ani, cu act de 
                        identitate sau certificat de naștere, după caz, valabil la dată solicitării;
                      </li>
                      <li>
                        pentru tinerii cu vârstă cuprinsă între 18 și 26 de ani: act de identitate valabil la dată solicitării, 
                        declarație pe propria răspundere din care să rezulte că nu realizează venituri din muncă sau alte 
                        venituri impozabile și un document 
                        valabil din care să rezulte că au calitatea de elev sau student avizat de instituția de învățământ;
                      </li>
                      <li>
                        studentul-doctorand care desfășura activități didactice, potrivit contractului de studii de doctorat, 
                        în limita a 4-6 ore convenționale didactice pe săptămâna: act de identitate valabil la dată solicitării, 
                        contractul în baza căruia desfășoară activități didactice în limita a 4-6 ore, declarație pe propria 
                        răspundere din care să rezulte că nu realizează venituri din muncă sau alte venituri impozabile, adeverință 
                        eliberată de instituția de învățământ superior din care să rezulte că are calitatea de student-doctorand;
                      </li>
                      <li>
                        pentru tinerii cu vârstă de până la 26 de ani, care provin din sistemul de protecție a copilului: 
                        act de identitate valabil la dată solicitării, document care să ateste că au fost incluși într-un sistem 
                        de protecție a copilului, declarație pe propria răspundere din care să rezulte că nu realizează venituri 
                        din muncă, un document eliberat de primăria din 
                        localitatea de domiciliu prin care să ateste că nu beneficiază de ajutor social în condițiile legii;
                      </li>
                      <li>
                        pentru soțul, soția și părinții aflăți în întreținerea unei persoane asigurate: act de identitate 
                        valabil la dată solicitării, documente care să ateste relația de rudenie sau căsătoria cu persoană 
                        asigurată, declarație pe propria răspundere din care să rezulte că nu realizează venituri proprii, 
                        precum și o declarație pe propria 
                        răspundere a persoanei asigurate prin care această declara că are în întreținere persoană respectivă;
                      </li>
                      <li>
                        pentru beneficiarii Decretului-lege nr. 118/1990 privind acordarea unor drepturi persoanelor 
                        persecutate din motive politice de dictatură instaurată cu începere de la 6 martie 1945, precum 
                        și celor deportate în străînătate ori constituite în prizonieri, republicat, cu completările 
                        ulterioare, ai Ordonanței Guvernului nr. 105/1999 privind acordarea unor drepturi persoanelor 
                        persecutate de către regimurile instaurate în România cu începere de la 6 septembrie 1940 până 
                        la 6 martie 1945 din motive etnice, aprobată cu modificări și completări prin Legea nr. 189/2000, 
                        cu modificările și completările ulterioare, ai Legii nr. 44/1994 privind veteranii de război, 
                        precum și unele drepturi ale invalizilor și văduvelor de război, republicată, cu modificările și 
                        completările ulterioare, ai Legii nr. 309/2002 privind recunoașterea și acordarea unor drepturi 
                        persoanelor care au efectuat stagiul militar în cadrul Direcției Generale a Serviciului Muncii în 
                        perioada 1950-1961, cu modificările și completările ulterioare, precum și persoanele prevăzute 
                        la art. 3 alin. (1) lit. b) pct. 1 din Legea recunoștinței față de eroii-martiri și luptătorii 
                        care au contribuit la victoria Revoluției române din decembrie 1989, precum și față de persoanele 
                        care și-au jertfit viață sau au avut de suferit în urmă revoltei muncitorești anticomuniste de la 
                        Brașov din noiembrie 1987 nr. 341/2004, cu modificările și completările ulterioare: act de identitate 
                        valabil la dată solicitării, documente doveditoare că se încadrează în una dintre categoriile de 
                        beneficiari ai acestor legi, declarație pe propria răspundere 
                        din care să rezulte că nu realizează venituri impozabile, altele decât cele prevăzute de legile speciale;
                      </li>
                      <li>
                        pentru persoanele cu handicap: act de identitate valabil la dată solicitării, certificat de 
                        încadrare într-un grad de handicap, declarație pe propria răspundere din care să rezulte că 
                        nu realizează venituri din muncă, 
                        pensie sau alte surse, în afară celor prevăzute de legea prin care li s-a stabilit această calitate;
                      </li>
                      <li>
                        pentru bolnavii cu afecțiuni incluse în programele naționale de sănătate stabilite de Ministerul 
                        Sănătății, până la vindecarea respectivei afecțiuni: act de identitate valabil la dată solicitării, 
                        adeverință medicală eliberată de medicul curant sau de medicul coordonator al programului de sănătate, 
                        declarație pe propria 
                        răspundere din care să rezulte că nu realizează venituri din muncă, pensie sau din alte resurse;
                      </li>
                      <li>
                        pentru femeile însărcinate sau lăuze: act de identitate valabil la dată solicitării, 
                        adeverință medicală, certificat de naștere al copilului - pentru lăuze, precum și declarație 
                        pe propria răspundere din care să rezulte că nu realizează venituri 
                        sau documente justificative că realizează venituri lunare sub salariul de baza minim brut pe țară;
                      </li>
                      <li>
                        pentru persoanele care se află în concediu pentru creșterea copilului până la împlinirea vârstei 
                        de 2 ani și, în cazul copilului cu handicap, până la împlinirea de către copil a vârstei de 3 ani 
                        sau se află în concediu și indemnizație pentru creșterea copilului cu handicap cu vârstă cuprinsă 
                        între 3 și 7 ani: actul de identitate valabil la dată solicitării și decizia emisă de direcțiile 
                        județene de muncă, familie și 
                        egalitate de șanse, respectiv de Direcția de Muncă, Familie și Egalitate de Şanse a Municipiului București;
                      </li>
                      <li>
                        pentru persoanele care beneficiază de indemnizație de șomaj, venit lunar de completare sau plăti 
                        compensatorii din bugetul asigurărilor de șomaj: actul de identitate valabil la dată solicitării, 
                        decizia și/sau adeverință eliberate/eliberată de instituțiile care administrează bugetul asigurărilor 
                        pentru șomaj sau cuponul mandatului poștal 
                        de achitare a drepturilor, talonul de plata prin cont deschis la o unitate bancară din luna anterioară;
                      </li>
                      <li>
                        pentru persoanele care sunt returnate sau expulzate ori sunt victime ale traficului de persoane 
                        și se află în timpul procedurilor necesare stabilirii identității: adeverință eliberată de instituțiile 
                        din subordinea Ministerului Afacerilor Interne, din care să rezulte că se află în această situație;
                      </li>
                      <li>
                        pentru persoanele care fac parte dintr-o familie care are dreptul la ajutor social, potrivit 
                        Legii nr. 416/2001 privind venitul minim garantat, cu modificările și completările ulterioare: 
                        actul de identitate valabil la dată solicitării, declarația pe propria răspundere din care să 
                        rezulte că nu realizează venituri impozabile și adeverință eliberată de primăria localității 
                        de domiciliu, din care să rezulte că 
                        acestea se încadrează în categoria persoanelor care au dreptul la ajutor social, în condițiile legii;
                      </li>
                      <li>
                        pentru persoanele care au calitatea de pensionari: actul de identitate valabil la dată solicitării, 
                        cuponul mandatului poștal de achitare a drepturilor, talonul de plata prin cont curent personal 
                        deschis la o unitate bancară, din luna anterioară, sau decizia de pensionare, după caz, pentru 
                        situația în care nu s-a realizat plata pensiei, decizia asupra capacității de muncă, precum și 
                        declarația pe propria răspundere din care să rezulte că nu realizează venituri impozabile, altele 
                        decât cele realizate din pensii. În cazul pierderii acestor documente sau până la dobândirea lor, 
                        calitatea de pensionar poate fi dovedită cu o adeverință eliberată de instituția plătitoare a pensiei;
                      </li>
                      <li>
                        pentru persoanele care se află în executarea măsurilor prevăzute la art. 109, 110, 124 și 125 din 
                        Legea nr. 286/2009 privind Codul penal, cu modificările și completările ulterioare, și pentru 
                        persoanele care se află în perioada de amânare sau de întrerupere a executării pedepsei privative 
                        de libertate: 
                        adeverință emisă de instituția respectivă sau de instituția în grijă căreia se află persoană;
                      </li>
                      <li>
                        pentru personalul monahal al cultelor recunoscute: actul de identitate valabil la dată solicitării, 
                        declarația pe propria răspundere din 
                        care să rezulte că nu realizează venituri impozabile și adeverință eliberată de unitățile de cult;
                      </li>
                      <li>
                        pentru persoanele asigurate care realizează venituri din salarii sau a celor asimilate acestora, 
                        care sunt supuse impozitului pe venit, așa cum sunt definite în Codul fiscal: actul de identitate 
                        valabil la dată solicitării și adeverință de salariat eliberată de angajator, 
                        al cărei model este prevăzut în anexă nr. 2 care face parte integrantă din prezențele norme metodologice;
                      </li>
                      <li>
                         pentru persoanele fizice cu venituri impozabile: actul de identitate valabil la dată 
                         solicitării și documentul justificativ că a început plata contribuției în condițiile legii;
                      </li>
                      <li>
                        pentru cetățenii străini prevăzuți la art. 2 alin. (1) lit. b), c), d) și e): documentul 
                        de identitate valabil la dată solicitării (carte de identitate, pașaport, permisul de ședere), 
                        precum și orice alt document justificativ că a început plata contribuției în condițiile legii;
                      </li>
                      <li>
                        pentru persoanele care nu realizează venituri: actul de identitate valabil la dată solicitării, 
                        precum și dovadă plății contribuției pe ultimele 6 luni și luna curentă, în condițiile legii.
                      </li>

                    </ol>
                  <li>
                    Asiguratul are obligația să anunțe în termen de 15 zile medicul de familie și casă de 
                    asigurări asupra modificărilor datelor de identitate 
                    sau a modificărilor referitoare la încadrarea lor într-o anumită categorie de asigurați.
                  </li>
                  <li>
                    În condițiile în care persoanele beneficiare ale documentului prevăzut la alin. (1) nu 
                    mai îndeplinesc una dintre condițiile în baza cărora au beneficiat de asigurarea socială de 
                    sănătate fără plata contribuției sau cu plata 
                    contribuției din alte surse, valabilitatea documentului justificativ încetează de drept de la acea dată.
                  </li>
                  <li>
                    Pentru persoanele care se află în incapacitate sau imposibilitate de a se deplasa 
                    la casă de asigurări, eliberarea adeverintelor se face prin corespondență, 
                    pe baza documentelor prevăzute la alin. (1) lit. a) - s), comunicate de aceste persoane în copie.
                  </li>
                  <li>
                    Casele de asigurări au obligația de a îndeplini orice demers legal potrivit domeniului 
                    lor de competență, astfel încât accesul asiguraților la pachetul de servicii 
                    medicale de baza să nu fie împiedicat de procesul de eliberare a adeverintelor de asigurat.
                  </li>
                  <li>
                    Procesul de eliberare a adeverintelor care atestă calitatea de asigurat este un proces continuu. 
                    Pentru asigurarea accesului liber la pachetul de servicii medicale de baza, persoanele asigurate 
                    în sistemul de asigurări sociale de sănătate pot face dovadă calității de asigurat prin accesarea 
                    de către furnizorii de servicii medicale, medicamente, îngrijiri la domiciliu și dispozitive 
                    medicale aflăți în relații contractuale cu casele de asigurări a 
                    instrumentului electronic pus la dispoziție de CNAS sau pe baza documentelor prevăzute la alin. (1).
                  </li>
                </ol>
                <h5>Art. 13</h5>
                <ol>
                  <li>
                    Documentul prevăzut la art. 12 alin. (1) are următoarea perioada de valabilitate:
                  </li>
                  <ol type='a'>
                    <li>
                      pentru persoanele prevăzute la art. 12 alin. (1) lit. a) 
                      adeverință este valabilă până la împlinirea vârstei de 18 ani;
                    </li>
                    <li>
                      pentru persoanele prevăzute la art. 12 alin. (1) lit. b) adeverință este valabilă pe 
                      durata anului școlar sau 
                      universitar și 3 luni de la dată finalizării acestuia, până la împlinirea vârstei de 26 de ani;
                    </li>
                    <li>
                      pentru persoanele prevăzute la art. 12 alin. (1) lit. c) adeverință este 
                      valabilă pe durata anului universitar;
                    </li>
                    <li>
                       pentru persoanele prevăzute la art. 12 alin. (1) lit. d) adeverință 
                       este valabilă până la împlinirea vârstei de 26 de ani;
                    </li>
                    <li>
                      pentru persoanele prevăzute la art. 12 alin. (1) lit. f) și g) adeverință este valabilă până la 
                      dată specificată în documentul justificativ sau 
                      până la închiderea categoriei potrivit protocolului încheiat cu instituția la care figurează;
                    </li>
                    <li>
                      pentru persoanele prevăzute la art. 12 alin. (1) lit. j), k), l), m), n), o) și p) adeverință 
                      este valabilă până la dată specificată în documentul justificativ sau până 
                      la închiderea categoriei în luna de raportare, efectuată de instituția abilitată cu raportarea;
                    </li>
                    <li>
                      pentru persoanele prevăzute la art. 12 alin. (1) lit. e), h) și i) adeverință 
                      este valabilă 3 luni de la dată emiterii;
                    </li>
                    <li>
                      pentru persoanele prevăzute la art. 12 alin. (1) lit. q), r) și s) adeverință 
                      este valabilă 3 luni de la dată emiterii.
                    </li>
                  </ol>
                  <li>
                    Pentru persoanele care nu realizează venituri, adeverință este eliberată în condițiile 
                    art. 12 alin. (1) lit. s) și are 
                    valabilitate pe întreagă perioada în care s-a plătit contribuția, în condițiile legii.
                  </li>
                </ol>
                <h5>Art. 14</h5>
                <p>
                  Furnizorii de servicii medicale, medicamente, îngrijiri la domiciliu și dispozitive medicale, 
                  aflăți în relații contractuale cu casele de asigurări, acordă serviciile din pachetul de servicii
                   medicale de baza persoanelor care fac dovadă calității de asigurat cu documentele prevăzute la art. 11 
                   și art. 12 alin. (1), până la 
                  dată implementării cardului național de asigurări sociale de sănătate.
                </p>
                <h5>CAPITOLUL IV - Dispoziții finale</h5>
                <h5>Art. 15</h5>
                <ol>
                  <li>Persoanele fizice și juridice care au calitatea de angajatori, entitățile asimilate angajatorului și 
                    persoanele fizice, după caz, au obligația de a calcula, de a reține și vira contribuția la termenele de 
                    plata a contribuției stabilite în conformitate cu prevederile Codului fiscal și obligația depunerii 
                    declarației privind obligațiile de 
                    plata a contribuțiilor sociale, impozitului pe venit și evidență nominală a persoanelor asigurate.</li>
                  <li>
                    În cazul plăților efectuate de persoanele fizice, obligația la fond se consideră plătită la dată înscrisă 
                    în documentul de plata eliberat de organele fiscale ale ANAF.
                  </li>
                  <li>
                    Pentru neachitarea la termenul de scadență de către angajatorii și asigurații care au obligația plății 
                    contribuției, aceștia datorează după acest termen obligații 
                    fiscale accesorii, potrivit prevederilor legale în materie de colectare a creanțelor fiscale.
                  </li>
                  <li>
                    Persoanele care nu pot face dovadă plății contribuției beneficiază numai de pachetul minimal de 
                    servicii medicale până la plata contribuției în 
                    condițiile legii. Diminuarea are loc după 3 luni de la ultima plata a contribuției datorate fondului.
                  </li>
                  <li>
                    Persoanele prevăzute la alin. (4) care încep să plătească contribuția curentă la fond au dreptul la 
                    pachetul de servicii medicale de baza de la dată începerii plății contribuției, 
                    urmând că sumele restante să fie recuperate de ANAF, în conformitate cu Codul de procedura fiscală.
                  </li>
                  <li>
                    În situația în care persoanele care sunt asigurate fără plata contribuției sau cu plata contribuției 
                    din alte surse nu mai îndeplinesc condițiile pentru a fi încadrate în una dintre aceste categorii, 
                    documentul prevăzut la art. 12 alin. (1) își încetează de drept valabilitatea, iar aceste persoane 
                    beneficiază numai de pachetul minimal de servicii medicale până la plata contribuției în condițiile legii.
                  </li>
                </ol>
                <h5>Art. 16</h5>
                <ol>
                  <li>
                    Evidență asiguraților la nivelul caselor de asigurări se realizează pe baza codului numeric 
                    personal sau codului unic de 
                    identificare din sistemul de asigurări sociale de sănătate, prin Sistemul informatic unic integrat.
                  </li>
                  <li>
                    Prin excepție de la prevederile alin. (1), persoanelor străine cărora nu li s-a atribuit codul 
                    numeric personal de către organele de specialitate ale Ministerului Afacerilor Interne, dar care 
                    trebuie luate în evidență de către casele de asigurări, 
                    li se atribuie în acest sens un număr unic de identificare în sistemul de asigurări sociale de sănătate.
                  </li>
                  <li>
                    Pentru categoriile de persoane prevăzute la alin. (2) adeverință de asigurat se eliberează 
                    pe baza numărului unic de identificare atribuit în sistemul de asigurări sociale de sănătate.
                  </li>
                </ol>
            </div>
        </Card>
    </Container>
  )
}

export default DocumenteNecesareAsiguratilor