import React from 'react'
import { Card, Container , Image } from 'react-bootstrap'

const ConsultatiiOnline = () => {
  return (
    <Container>
      <Card className='box-shadow-left m-3 p-3'>
        <h1 className='m-3 p-3'>Consultații online</h1>
        <h5>Sfaturi medicale gratuite - Întreabă Doctorul</h5>
        <p>
          Fă primul pas să descoperi ce ar putea determina simptomele tale.
        </p>
        <p>
          Mersul la doctor poate fi uneori dificil datorită activităților 
          dumneavoastră zilnice, fricii de a va descoperi o problema, neglijării durerilor sau lipsei banilor.
        </p>
        <p>
          Venim în spijinul dumneavoastră cu un sfat medical profesionist care să îl 
          primiți în confortul și confidențialitatea căminului dumneavoastră.
        </p>
        <p>
          Pornind de la ideea că dintre toate nenorocirile care se abat asupra noastră în cursul 
          vieții, cele mai devastatoare sunt cele cauzate de noi înșine, dorim să va informăm și 
          să va oferim soluții optime, conform celor mai noi protocoale terapeutice, problemelor 
          dumneavoastră de sănătate. Nu trebuie să suspectați o boală doar când insuficientă de 
          organ este clinic manifestă, febra este maximă sau durerea este insuportabilă! 
          Nu trebuie să ajungeți la amputatia piciorului pentru că ați neglijat un diabet zaharat! 
          Nu trebuie să se instaleze orbirea pentru că ani de zile nu ați discutat cu cineva despre 
          problemele dumneavoastră! 
          Nu așteptați un accident vascular cerebral pentru a află despre complicațiile hipertensiunii arteriale!
        </p>
        <p>
          Doar un om bine informat este un sprijin real pentru familia să și societate. 
          Noi va ajutăm, scrieți-ne despre problemele dumneavoastră și ce anume va interesează. 
          Va vom răspunde în email, respectându-va confidențialitatea. Va rog să ne furnizați 
          cât mai multe informații despre starea dumneavoastră de sănătate 
          (probleme personale de sănătate și ale rudelor de gradul I, internări, tratamente folosite etc.) 
          pentru nu va da sfaturi inutile care nu se potrivesc stilului dumneavoastră de viață.
        </p>
        <p><b style={{color:"red"}}>Răspunsurile noastre nu substituie, totuși examinarea într-un cabinet medical și 
        efectuarea de investigații medicale. Nu ezitați să vă vizitați medicul. </b></p>

      </Card>
    </Container>
  )
}

export default ConsultatiiOnline